import React, { useState } from "react";
import { Link } from "gatsby";
import { data } from "../data/index";

const NavBar = () => {
  const [toggleClass, setToggleClass] = useState(false);
  return (
    <>
      <div className="menubar" onClick={() => setToggleClass(!toggleClass)}>
        <div className={`menu-bar ${toggleClass ? "active" : null}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`mainmenu-area ${toggleClass ? "active" : null}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mainmenu-main">
                <ul>
                  {data.navMenu.map((nav, index) => {
                    return (
                      <li key={index}>
                        <Link to={nav.link}>{nav.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
