import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { data } from "../data/index";
import HtmlToReactParser from "html-to-react";

const htmlParser = HtmlToReactParser.Parser;

const Header = () => {
  const htmlToReactParser = new htmlParser();
  return (
    <React.Fragment>
      <div className="header-area">
        <div className="container">
          <div className="row header-row align-items-center">
            <div className="col-lg-4 col-md-4">
              <div className="header-text">
                <p>{htmlToReactParser.parse(data.header.title)}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="logo">
                <Link to="/">
                  <StaticImage src="../images/logo.png" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="header-text alignright">
                <p>{htmlToReactParser.parse(data.header.callUs)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
