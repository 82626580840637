import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./Navbar";
import "../all.scss";
import { Helmet } from "react-helmet";

const Layout = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {/* adding font-awesome icons */}
        <script
          src="https://kit.fontawesome.com/02130b3d51.js"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <Header />
      <NavBar />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
