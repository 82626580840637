import React from "react";
import { Link } from "gatsby";
import { data } from "../data/index";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-menu">
              <ul>
                {data.navMenu.map((nav, index) => {
                  return (
                    <li key={index}>
                      <Link to={nav.link}>{nav.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-lg-7 col-md-7">
            <div className="footer-left">
              <h4>
                <a href="/">TASTY PIZZA</a>
              </h4>
              <p>Copyright © {year} Tasty Pizza</p>
              <div className="footer-link">
                <a
                  href="https://www.facebook.com/people/Tasty-Pizza/100091965769509/"
                  target="_blank"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/tastypizzadartmouth/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-5 col-md-5">
            <div className="footer-right">
              <p>Developed with GastbyJS</p>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
